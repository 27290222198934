import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  @Output() httpError = new EventEmitter<HttpErrorResponse>();

  constructor(private http: HttpClient) { }

  get(url: string, headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.get(`${environment.apiUrl}${url}`, { headers: this.generateHeaders(headers) });
  }

  post(url: string, data: Record<string, any>, headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.post(`${environment.apiUrl}${url}`, data, { headers: this.generateHeaders(headers) });
  }

  patch(url: string, data: Record<string, any>, headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.patch(`${environment.apiUrl}${url}`, data, { headers: this.generateHeaders(headers) });
  }

  delete(url: string, headers?: Record<string, string | string[]>): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${url}`, { headers: this.generateHeaders(headers) });
  }

  private generateHeaders(headers?: Record<string, string | string[]>): HttpHeaders {
    if (headers) {
      return new HttpHeaders({ ...headers, 'Content-Type': 'application/json' });
    }
    return new HttpHeaders({ 'Content-Type': 'application/json' });
  }

  handleHttpError(error: HttpErrorResponse): void {
    this.httpError.emit(error);
  }
}
