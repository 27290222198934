import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Browser, MobileDevice } from 'src/app/models/device';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  detectMobileDevice(): Observable<MobileDevice> {
    return new Observable(observer => {
      const isAndroidDevice = /android/i.test(navigator.userAgent);
      const isIOSDevice = [
         'iPad Simulator',
         'iPhone Simulator',
         'iPod Simulator',
         'iPad',
         'iPhone',
         'iPod'
       ].includes(navigator.platform)
       // iPad on iOS 13 detection
       || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

       if (isAndroidDevice === isIOSDevice) {
        observer.next(MobileDevice.UNKNOWN);
       } else if (isAndroidDevice) {
        observer.next(MobileDevice.ANDROID);
       } else {
        observer.next(MobileDevice.APPLE);
       }

       observer.complete();
     });
  }

  detectBrowser(): Observable<Browser> {
    return new Observable(observer => {
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#browser_name
      let browser = Browser.OTHER;

      const containsChrome = navigator.userAgent.indexOf('Chrome/') > -1;
      const containsCriOS = navigator.userAgent.indexOf('CriOS/') > -1;
      const containsChromium = navigator.userAgent.indexOf('Chromium/') > -1;
      const containsSafari = navigator.userAgent.indexOf('Safari/') > -1;
      if ((containsChrome || containsCriOS) && !containsChromium) {
        browser = Browser.CHROME;
      } else if (containsSafari && !containsChrome && !containsCriOS && !containsChromium) {
        browser = Browser.SAFARI;
      }

      observer.next(browser);
      observer.complete();
    });
  }

  microphonePermissionAllowed(): Observable<boolean> {
    return new Observable(observer => {
      navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch(error => {
          observer.next(false);
          observer.complete();
        });
    });
  }

  locationPermissionAllowed(): Observable<boolean> {
    return new Observable(observer => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          observer.next(true);
          observer.complete();
        },
        () => {
          observer.next(false);
          observer.complete();
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }
}
