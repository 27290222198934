import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpService } from '../../providers/http/services/http.service';

@Component({
  selector: 'app-intercom',
  templateUrl: './intercom.component.html',
  styleUrls: ['./intercom.component.scss']
})
export class IntercomComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  constructor(
    private httpService: HttpService,
    private router: Router
  ) {
    this.httpService.httpError.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(error => {
      if (error.status === 401 || error.status === 403) {
        this.router.navigateByUrl('no-auth');
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  routerChange(): void {
    window.scrollTo(0, 0);
  }

}
