import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Browser, MobileDevice } from 'src/app/models/device';
import { DeviceService } from 'src/app/services/device/device.service';
import { IntercomService } from 'src/app/services/intercom/intercom.service';
import { LatchDialogService } from '@latch/latch-web';
import { FeedbackSelectorComponent } from '../../components/feedback-selector/feedback-selector.component';

@Component({
  selector: 'app-no-permissions',
  templateUrl: './no-permissions.component.html',
  styleUrls: ['./no-permissions.component.scss']
})
export class NoPermissionsComponent implements OnInit {
  title = '';
  deviceCaption = 'Could not identify platform';
  permissionSteps: string[] = [];

  private isMicAllowed = false;
  private isLocationAllowed = false;
  private device: MobileDevice = MobileDevice.UNKNOWN;
  private browser: Browser = Browser.OTHER;

  constructor(
    private intercomService: IntercomService,
    private deviceService: DeviceService,
    private dialog: LatchDialogService,
    ) { }

  ngOnInit(): void {
    forkJoin([
      this.deviceService.detectMobileDevice(),
      this.deviceService.detectBrowser(),
      this.deviceService.locationPermissionAllowed(),
      this.deviceService.microphonePermissionAllowed()
    ]).subscribe(([mobileDevice, browser, isLocationAllowed, isMicAllowed]) => {
      this.isLocationAllowed = isLocationAllowed;
      this.isMicAllowed = isMicAllowed;

      this.device = mobileDevice;
      this.browser = browser;

      this.title = !isLocationAllowed ? 'Cannot access location details.' : 'Cannot access microphone.';
      switch (this.device) {
        case MobileDevice.ANDROID:
          this.deviceCaption = 'Android device detected';
          this.permissionSteps = this.getAndroidSteps();
          break;
        case MobileDevice.APPLE:
          this.deviceCaption = 'iOS device detected';
          this.permissionSteps = this.getAppleSteps();
          break;
        default:
          this.deviceCaption = 'Unknown device';
      }

      setTimeout(() => {
        this.showFeedbackModal();
      }, 10000);
    });
  }

  reloadSite() {
    // first try to get previous URL and refresh page to that URL
    // if not we will check if we have intercom JWT token and try to go to homepage
    // we will always reload a page to be sure permission changes will be applied
    if (history.state?.previousURL) {
      window.location.href = history.state.previousURL;
    } else {
      window.location.href = `intercoms/${this.intercomService.authToken}`;
    }
  }

  private getAndroidSteps(): string[] {
    const chromeLocationSteps = [
      'Go to Settings > Location.',
      'Make sure Location access is turned on.',
      'Go to Settings > Apps.',
      'Go to Manage Apps (if apps are not listed immediately).',
      'Find Chrome application and tap on it.',
      'Open App permissions.',
      'Find “Location” and allow access.'
    ];
    const chromeMicSteps = [
      'Go to Settings > Apps.',
      'Go to Manage Apps (if apps are not listed immediately).',
      'Find Chrome application and tap on it.',
      'Open App permissions.',
      'Find “Microphone” and allow access.'
    ];
    const otherLocationSteps = [
      'Go to Settings > Location.',
      'Make sure Location access is turned on.',
      'Go to Settings > Apps.',
      'Go to Manage Apps (if apps are not listed immediately).',
      'Find Web Browser application and tap on it.',
      'Open App permissions.',
      'Find “Location” and allow access.'
    ];
    const otherMicSteps = [
      'Go to Settings > Apps.',
      'Go to Manage Apps (if apps are not listed immediately).',
      'Find Web Browser application and tap on it.',
      'Open App permissions.',
      'Find “Microphone” and allow access.'
    ];

    if (!this.isLocationAllowed) {
      return this.browser === Browser.CHROME ? chromeLocationSteps : otherLocationSteps;
    } else if (!this.isMicAllowed) {
      return this.browser === Browser.CHROME ? chromeMicSteps : otherMicSteps;
    }
    return [];
  }

  private getAppleSteps(): string[] {
    const safariLocationSteps = [
      'Go to Settings > Privacy > Location Services.',
      'Make sure Location Services is turned on.',
      'Scroll down to Safari app and allow access.'
    ];
    const safariMicSteps = [
      'Go to Settings > Safari.',
      'Scroll down to Settings For Website.',
      'Allow access for Microphone.'
    ];
    const chromeLocationSteps = [
      'Go to Settings > Privacy > Location Services.',
      'Make sure Location Services is turned on.',
      'Scroll down to Chrome app and allow access.'
    ];
    const chromeMicSteps = [
      'Go to Settings > Chrome.',
      'Allow access for Microphone.'
    ];
    const otherLocationSteps = [
      'Go to Settings > Privacy > Location Services.',
      'Make sure Location Services is turned on.',
      'Scroll down to “Used Web Browser” app and allow access.'
    ];
    const otherMicSteps = [
      'Go to Settings > Used Web Browser app.',
      'Allow access for Microphone.'
    ];

    if (!this.isLocationAllowed) {
      return this.browser === Browser.SAFARI ? safariLocationSteps :
        (this.browser === Browser.CHROME ? chromeLocationSteps : otherLocationSteps);
    } else if (!this.isMicAllowed) {
      return this.browser === Browser.SAFARI ? safariMicSteps :
        (this.browser === Browser.CHROME ? chromeMicSteps : otherMicSteps);
    }
    return [];
  }

  private showFeedbackModal(): void {
    this.dialog.open(FeedbackSelectorComponent, {
      width: '75%',
      height: 'auto',
      data: this.isLocationAllowed,
    });
  }
}
