import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { IntercomComponent } from './pages/intercom/intercom.component';
import { NoAuthComponent } from './pages/no-auth/no-auth.component';
import { HomeComponent } from './pages/home/home.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HttpProviderModule } from './providers/http/http-provider.module';
import { UnitResidentsComponent } from './pages/unit-residents/unit-residents.component';
import { UnitsComponent } from './pages/units/units.component';
import { VoiceCallComponent } from './components/voice-call/voice-call.component';
import { DebounceDirective } from './directives/debounce/debounce.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { LatchAnalyticsModule, LatchCheckboxModule, LatchLogModule, LATCH_LOG_ERROR_ENDPOINT_BLACKLIST } from '@latch/latch-web';
import { FormatTimePipe } from './pipes/format-time/format-time.pipe';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { NoPermissionsComponent } from './pages/no-permissions/no-permissions.component';
import { IssueSelectorComponent } from './components/issue-selector/issue-selector.component';
import { FeedbackSelectorComponent } from './components/feedback-selector/feedback-selector.component';
import { ConsoleApiName } from '@datadog/browser-core';

const DATADOG_ERROR_ENDPOINT_BLACKLIST = ['/v1/doorCode'];
const APPLICATION_NAME = 'Latch-VirtualIntercomWeb';

@NgModule({
  declarations: [
    AppComponent,
    IntercomComponent,
    NoAuthComponent,
    HomeComponent,
    LoaderComponent,
    UnitResidentsComponent,
    UnitsComponent,
    VoiceCallComponent,
    DebounceDirective,
    FormatTimePipe,
    WelcomeComponent,
    NoPermissionsComponent,
    IssueSelectorComponent,
    FeedbackSelectorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpProviderModule,
    ReactiveFormsModule,
    LatchCheckboxModule,
    LatchLogModule.forRoot({
      enableLogging: environment.enableLogging,
      datadog: environment.datadogToken ? {
        datadogToken: environment.datadogToken,
        environmentName: environment.name,
        service: 'virtual-intercom-frontend',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: [ConsoleApiName.debug, ConsoleApiName.info, ConsoleApiName.log, ConsoleApiName.warn, ConsoleApiName.error]
      } : undefined,
    }),
    LatchAnalyticsModule.forRoot({
      enableAnalytics: environment.enableAnalytics ?? false,
      environmentName: environment.name,
      heap: environment.heapApplicationId ? {
        applicationId: environment.heapApplicationId,
        client: APPLICATION_NAME,
      } : undefined,
    })
  ],
  providers: [
    { provide: LATCH_LOG_ERROR_ENDPOINT_BLACKLIST, useValue: DATADOG_ERROR_ENDPOINT_BLACKLIST },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
