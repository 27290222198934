import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CallService } from 'src/app/services/call/call.service';
import { ResidentContactType, Unit, UnitResident } from '../../models/unit';
import { LoaderService } from '../../services/loader/loader.service';
import { UnitService } from '../../services/unit/unit.service';

@Component({
  selector: 'app-unit-residents',
  templateUrl: './unit-residents.component.html',
  styleUrls: ['./unit-residents.component.scss']
})
export class UnitResidentsComponent implements OnInit, OnDestroy {
  public unitResidentsLoader = false;
  public unit!: Unit;
  public unitResidents: UnitResident[] = [];
  public ResidentContactType = ResidentContactType;

  private unitId!: number;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
    private unitService: UnitService,
    private callService: CallService
  ) {
  }

  ngOnInit(): void {
    this.activeRoute.params.pipe(
      filter(params => params.unitId),
      takeUntil(this.unsubscribe$)
    ).subscribe(params => {
      this.unitId = parseInt(params.unitId, 10);
      this.getUnitData();
    });

    this.callService.callEnded$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(ended => {
      if (ended && (!this.unitResidents || this.unitResidents.length === 1)) {
        this.router.navigate(['../../../units'], { relativeTo: this.activeRoute });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setCallResident(resident: UnitResident): void {
    this.callService.callResident(resident);
  }

  private getUnitData(): void {
    this.loaderService.openLoader();
    this.unitResidentsLoader = true;
    this.unitService.getUnit(this.unitId).pipe(
      tap(unit => this.unit = unit),
      switchMap(() => this.unitService.getUnitResidents(this.unitId)),
      finalize(() => {
        this.loaderService.closeLoader();
        this.unitResidentsLoader = false;
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(unitResidents => {
      this.unitResidents = unitResidents;
      if (this.unitResidents.length === 1) {
        this.setCallResident(this.unitResidents[0]);
      }
    });
  }

}
