import { Component, HostListener, OnInit } from '@angular/core';
import { LatchAnalyticsService } from '@latch/latch-web';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private analyticsService: LatchAnalyticsService
  ) {
    this.setCssVariables();
  }

  @HostListener('window:resize') onResize(): void {
    this.setCssVariables();
  }

  ngOnInit(): void {
    this.analyticsService.track('VI Application Session');
  }

  private setCssVariables(): void {
    const html = document.getElementsByTagName('html')[0];
    if (html) {
      html.style.setProperty('--window-height', window.innerHeight.toString() + 'px');
    }
  }
}
