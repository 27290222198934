import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LatchAnalyticsService } from '@latch/latch-web';
import { Observable } from 'rxjs';
import { IntercomService } from '../../services/intercom/intercom.service';
import { decodeJWT } from '../../utilities/decode-jwt';

@Injectable({
  providedIn: 'root'
})
export class IntercomGuardV2 implements CanActivate {
  constructor(
    private intercomService: IntercomService,
    private router: Router,
    private analyticsService: LatchAnalyticsService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.params.tokenV2) {
      this.intercomService.setAuthToken(route.params.tokenV2);
      this.analyticsService.track('VI Initial Session Data', { token: route.params.tokenV2 });
      return true;
    }
    this.router.navigateByUrl('/no-auth');
    return false;
  }
}
