<div class="page">
  <div class="page-wrapper loader-container">
    <div class="page-header">
      <div class="page-title">Building Directory</div>
      <div class="form-control-container with-icon left">
        <i class="fa fa-search" aria-hidden="true"></i>
        <input class="form-control" placeholder="Search" [formControl]="search" [appDebounce]="debounce" (debounce)="searchDebounce()">
      </div>
    </div>
    <div class="list top-border" *ngIf="filteredUnits.length > 0 || filteredFeaturedEntries.length > 0">
      <div class="list-header" *ngIf="filteredFeaturedEntries.length > 0">Featured Entries</div>
      <a id="featuredEntity{{entity.id}}" *ngFor="let entity of filteredFeaturedEntries" (click)="setCallResident(entity)">
        <div class="list-element with-button">
          <div class="text-holder">
            {{entity.name}}
          </div>
          <div class="button-holder">
            <button class="button blue">Call</button>
          </div>
        </div>
      </a>
      <div class="list-header" *ngIf="filteredUnits.length > 0">Units</div>
      <a id="unit{{unit.id}}" *ngFor="let unit of filteredUnits" [routerLink]="['../units/' + unit.id + '/residents']">
        <div class="list-element with-icon-right">
          {{unit.name}}
          <i class="fa fa-angle-right icon-right" aria-hidden="true"></i>
        </div>
      </a>
    </div>
    <div class="page-content" *ngIf="filteredUnits.length === 0 && !dataLoading">
      There are no units connected to the intercom
    </div>
  </div>
</div>
