<div class="page full-height black-background">
  <div class="page-wrapper with-logo">
    <div class="page-logo">
      <img src="./../../../assets/images/latch-logo-white.svg">
    </div>
    <div class="page-content">
      <div class="page-title">Oh no, the directory is down for maintenance.</div>
      <div class="page-description">
        The Latch Link directory is not available at this time. Please contact your host or building management directly for access.
      </div>
    </div>
  </div>
</div>
