import { ResidentContactType } from './unit';

export type CallStatus =
  'uninitialized' |
  'started' |
  'ringing' |
  'failed' |
  'cancelled' |
  'answered' |
  'busy' |
  'timeout' |
  'rejected' |
  'completed' |
  'unanswered';

export type CallButtonsLabel = 'none' | 'call' | 'back';

export type CallDurationLabel = 'none' | 'start' | 'end';

export enum CallStatusEvent {
  UNINITIALIZED = 'UNINITIALIZED',
  RINGING = 'RINGING',
  ANSWERED = 'ANSWERED',
  CANCELED = 'CANCELED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  BUSY = 'BUSY',
  TIMEOUT = 'TIMEOUT',
  REJECTED = 'REJECTED',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN'
}

export interface CallToken {
  jwt: string;
}

export enum CalleeType {
  RESIDENT = 'RESIDENT',
  MANAGER = 'MANAGER'
}

export interface Calle {
  type: CalleeType;
  id: number;
}

export interface CallRequest {
  externalId: string;
  startTime: number;
  callMethod: ResidentContactType;
  callee: Calle;
}

export interface Call extends CallRequest {
  id: number;
}

export interface CallResponse {
  call: Call;
  callToken: string;
}

export interface CallEventRequest {
  status: CallStatusEvent;
  timestamp: number;
}

export interface CallEventResponse extends CallEventRequest {
  id: number;
}
