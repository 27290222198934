export enum IssueType {
  POOR_CONNECTION = 'poorConnection',
  CANNOT_ESTABLISH_CALL = 'cannotEstablishCall',
  WRONG_UNLOCK_CODE = 'wrongUnlockCode',
  CANNOT_GET_UNLOCK_CALL = 'cannotGetUnlockCode',
  NOT_COMFORTABLE_SHARING_LOCATION = 'notComfortableSharingLocation',
  OTHER = 'other'
}

export interface Issue {
  key: IssueType,
  displayText: string
}
