import { Pipe, PipeTransform } from '@angular/core';
import { FormatTimeData, FormatTimeElement, TimeKey } from '../../models/format-time';

const timeElements: FormatTimeElement[] = [
  {
    key: 'y',
    index: 365 * 24 * 60 * 60
  },
  {
    key: 'mo',
    index: 30 * 24 * 60 * 60
  },
  {
    key: 'd',
    index: 24 * 60 * 60
  },
  {
    key: 'h',
    index: 60 * 60
  },
  {
    key: 'm',
    index: 60
  },
  {
    key: 's',
    index: 1
  }
];

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(timeValue: number, timeKey: TimeKey = timeElements[0].key, showEmpty = false, minElements = 2): string {
    const formatedValues: FormatTimeData[] = [];
    const countdownStartIndex = timeElements.findIndex(ce => ce.key === timeKey);
    const positiveValue = timeValue >= 0;
    timeValue = Math.abs(timeValue);
    if (timeValue > 0) {
      for (let i = countdownStartIndex; i < timeElements.length; i++) {
        const value = Math.floor(timeValue / timeElements[i].index);
        if ((timeValue > 0 && value === 0 && showEmpty) || timeValue === 0 || value > 0) {
          formatedValues.push({
            label: timeElements[i].key,
            value: this.getTwoDigits(value)
          });
        }
        timeValue -= (value * timeElements[i].index);
      }
    }
    while (formatedValues.length < minElements) {
      const index = timeElements.length - formatedValues.length;
      formatedValues.unshift({
        label: timeElements[index].key,
        value: this.getTwoDigits(0)
      });
    }
    const formatedValue = formatedValues.map(fv => fv.value).join(':');
    return positiveValue ? formatedValue : `-${formatedValue}`;
  }

  private getTwoDigits(value: number): string {
    if (value < 10) {
      return `0${value}`;
    }
    return value.toString();
  }
}
