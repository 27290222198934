<div class="page">
  <div class="page-wrapper loader-container">
    <div class="page-header" *ngIf="unit">
      <div>
        <i class="fa fa-angle-left header-icon" [routerLink]="['../../../units']"></i>
      </div>
      <div class="page-title">{{unit.name}}</div>
    </div>
    <div class="list" *ngIf="unitResidents.length > 0">
      <div class="list-header">
        {{unitResidents.length}} Residents
      </div>
      <div class="list-element with-button" *ngFor="let resident of unitResidents">
        <div class="text-holder">{{resident.name}}</div>
        <div class="button-holder">
          <button id="callResident{{resident.id}}" class="button blue" (click)="setCallResident(resident)">Call</button>
        </div>
      </div>
    </div>
    <div class="page-content" *ngIf="unitResidents.length === 0 && !unitResidentsLoader">
      There are no residents connected to the unit
    </div>
</div>
