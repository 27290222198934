import { Component, OnInit } from '@angular/core';
import { LatchVersionInfoService } from '@latch/latch-web';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public versionNumber = '';

  ngOnInit() {
    this.versionNumber = LatchVersionInfoService.getHumanReadableVersion();
  }
}
