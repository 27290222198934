import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

declare const NexmoClient: any;

@Injectable({
  providedIn: 'root'
})
export class NexmoService {
  getApp(nexmoJWT: string): Observable<any> {
    return new Observable((observer) => {
      new NexmoClient({ 
        debug: environment.production ? "info" : "debug"
      })
      .createSession(nexmoJWT)
      .then((app: any) => {
        observer.next(app);
        observer.complete();
      }, (error: any) => observer.error(error) )
      .catch((error: any) => observer.error(error));
    });
  }
}
