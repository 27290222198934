import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  LatchAnalyticsService,
  logInfo,
  logError,
  LatchDialogService,
  LatchDialogConfig,
  LatchConfirmationDialogConfig
} from '@latch/latch-web';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { CallButtonsLabel, CallDurationLabel, CalleeType, CallStatus, CallStatusEvent } from '../../models/call';
import { ResidentContact, ResidentContactType, UnitResident } from '../../models/unit';
import { CallService } from '../../services/call/call.service';
import { IntercomService } from '../../services/intercom/intercom.service';
import { NexmoService } from '../../services/nexmo/nexmo.service';
import { FeedbackSelectorComponent } from '../feedback-selector/feedback-selector.component';

const unlockPoolingInterval = 3000;

const statusText: { [key in CallStatus]: string } = {
  ['uninitialized']: 'Calling...',
  ['started']: 'Calling...',
  ['ringing']: 'Calling...',
  ['failed']: 'No answer',
  ['cancelled']: 'Call failed, please try again',
  ['answered']: 'Connected',
  ['busy']: 'Call failed, please try again',
  ['timeout']: 'No answer',
  ['rejected']: 'No answer',
  ['completed']: 'Call ended',
  ['unanswered']: 'No answer'
};

const buttonsLabel: { [key in CallStatus]: CallButtonsLabel } = {
  ['uninitialized']: 'none',
  ['started']: 'call',
  ['ringing']: 'call',
  ['failed']: 'back',
  ['cancelled']: 'back',
  ['answered']: 'call',
  ['busy']: 'back',
  ['timeout']: 'back',
  ['rejected']: 'back',
  ['completed']: 'back',
  ['unanswered']: 'back'
};

const callDurationLabel: { [key in CallStatus]: CallDurationLabel } = {
  ['uninitialized']: 'none',
  ['started']: 'start',
  ['ringing']: 'none',
  ['failed']: 'end',
  ['cancelled']: 'end',
  ['answered']: 'none',
  ['busy']: 'end',
  ['timeout']: 'end',
  ['rejected']: 'end',
  ['completed']: 'end',
  ['unanswered']: 'end'
};

const callStatusToEvent: { [key in CallStatus]: CallStatusEvent } = {
  ['uninitialized']: CallStatusEvent.UNINITIALIZED,
  ['started']: CallStatusEvent.STARTED,
  ['ringing']: CallStatusEvent.RINGING,
  ['failed']: CallStatusEvent.FAILED,
  ['cancelled']: CallStatusEvent.CANCELED,
  ['answered']: CallStatusEvent.ANSWERED,
  ['busy']: CallStatusEvent.BUSY,
  ['timeout']: CallStatusEvent.TIMEOUT,
  ['rejected']: CallStatusEvent.REJECTED,
  ['completed']: CallStatusEvent.COMPLETED,
  ['unanswered']: CallStatusEvent.UNKNOWN
};

@Component({
  selector: 'app-voice-call',
  templateUrl: './voice-call.component.html',
  styleUrls: ['./voice-call.component.scss']
})
export class VoiceCallComponent implements OnInit, OnDestroy {
  @Input() resident!: UnitResident;
  @Output() closeCall = new EventEmitter<null>();

  public callStatus!: CallStatus;
  public statusText!: string;
  public nexmoJWT!: string;
  public buttonsLabel: { [key in CallStatus]: CallButtonsLabel } = buttonsLabel;
  public muteMember = false;
  public unlockCode = '';
  public callDuration = 0;

  private callId!: number;
  private callToken?: string;
  private unsubscribe$ = new Subject<void>();
  private nexmoApp: any | null;
  private nexmoCall: any | null;
  private nexmoMember: any | null;
  private unlockPoolingInterval: any;
  private unlockPoolingDelayTimeout: any;
  private showFeedbackModalTimeout: any;
  private callDurationInterval: any;

  constructor(
    private ngZone: NgZone,
    private nexmoService: NexmoService,
    private intercomService: IntercomService,
    private callService: CallService,
    private analyticsService: LatchAnalyticsService,
    private router: Router,
    private dialog: LatchDialogService
  ) { }

  ngOnInit(): void {
    this.analyticsService.track('VI Voice Call');
    this.setCallStatus('uninitialized');
    this.callService.getToken().pipe(
      tap(callToken => this.nexmoJWT = callToken.jwt),
      switchMap(() => this.nexmoService.getApp(this.nexmoJWT)),
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (app: any) => {
        this.nexmoApp = app;
        this.initCall(this.resident.contact);
      },
      error => {
        logError('Error initializing call: ' + String(error));
        logError('Error initializing call (JSON): ' + JSON.stringify(error));
        if (error.status === 429) {
          const message = `<div class="content-center">
            <i class="fa text-red fa-4x fa-exclamation-circle"></i>
            <br/>
            <h3>Call limit reached</h3>
            You’ve reached the call limit. Please try again in 1 minute.
          </div>`;
          this.showErrorModal(message);
        }
        this.setCallStatus('failed');
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.unlockPoolingInterval) {
      clearInterval(this.unlockPoolingInterval);
    }
    if (this.unlockPoolingDelayTimeout) {
      clearTimeout(this.unlockPoolingDelayTimeout);
    }
    if (this.callDurationInterval) {
      this.endCallDuration();
    }
    if (this.showFeedbackModalTimeout) {
      clearTimeout(this.showFeedbackModalTimeout);
    }
  }

  hangUp(): void {
    if (this.nexmoCall) {
      this.nexmoCall.hangUp();
    }
  }

  toggleMuteMember(): void {
    this.muteMember = !this.muteMember;
    this.setNexmoMuteMember();
  }

  showFeedbackModal(): void {
    this.dialog.open<FeedbackSelectorComponent, LatchDialogConfig, string>(FeedbackSelectorComponent, {
      width: '75%',
      height: 'auto',
    });
  }

  backToDirectory(): void {
    this.closeCall.emit();
  }

  private checkUnlockCode(): void {
    if (this.callToken) {
      this.intercomService.checkIntercomUnlock(this.callToken).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(
        intercomUnlock => {
          if (intercomUnlock.doorCode) {
            this.unlockCode = intercomUnlock.doorCode;
            clearInterval(this.unlockPoolingInterval);
          }
        },
        error => {
          if (error) {
            logInfo(error.message);
          }
        }
      );
    }
  }

  private startUnlockPooling(): void {
    this.unlockCode = '';
    if (!this.unlockPoolingInterval) {
      this.unlockPoolingInterval = setInterval(() => this.checkUnlockCode(), unlockPoolingInterval);
    }
  }

  private startCallDuration(): void {
    if (!this.callDurationInterval) {
      this.callDurationInterval = setInterval(() => this.callDuration += 1, 1000);
    }
  }

  private endCallDuration(): void {
    if (this.callDurationInterval) {
      clearInterval(this.callDurationInterval);
    }
  }

  private setCallStatus(status: CallStatus): void {
    this.ngZone.run(() => {
      this.callStatus = status;
      this.statusText = statusText[this.callStatus];
      if (this.callStatus === 'answered') {
        this.startUnlockPooling();
      } else if (this.unlockPoolingInterval) {
        this.unlockPoolingDelayTimeout = setTimeout(() => {
          clearInterval(this.unlockPoolingInterval);
        }, 4000);
      }
      if (callDurationLabel[this.callStatus] === 'start') {
        this.startCallDuration();
      } else if (callDurationLabel[this.callStatus] === 'end') {
        this.endCallDuration();
        this.showFeedbackModalTimeout = setTimeout(() => {
          this.showFeedbackModal();
        }, 10000);
      }
    });
  }

  private initCall(residentContact: ResidentContact): void {
    if (residentContact.type === ResidentContactType.APP || residentContact.type === ResidentContactType.UNIT) {
      this.makeAppCall(residentContact.details);
    } else if (residentContact.type === ResidentContactType.PHONE) {
      this.makePhoneCall(residentContact.details);
    }
  }

  private makeAppCall(caleeDetails: string): void {
    logInfo("Making app call, nexmo session - " + this.nexmoApp?.session?.session_id)
    this.handleMemberCall();
    this.handleCallStatusChange();
    this.nexmoApp.callServer(caleeDetails, 'app').catch((error: any) => {
      if (this.callId) {
        this.setCallEvent(this.callId, CallStatusEvent.ERROR);
      }
      logError(`Nexmo could not make a call to app, message: ${error?.message}`, { nexmoFailure: true, nexmoError: error?.message });
      logError(`Nexmo could not make a call to app, message's message: ${error?.message?.message}`, { nexmoFailure: true, nexmoError: error?.message });
      logError(`Nexmo could not make a call to app, error (JSON): ${JSON.stringify(error)}, session_id: ${this.nexmoApp?.session?.session_id}`);
      logError(`Nexmo could not make a call to app, error (JSON+): ${JSON.stringify(error, ['message', 'name', 'error', 'arguments', 'type'])}, session_id: ${this.nexmoApp?.session?.session_id}`);
      logError(`Nexmo could not make a call to app, error message (JSON): ${JSON.stringify(error.message)}, session_id: ${this.nexmoApp?.session?.session_id}`);
      console.log(error);
      this.router.navigateByUrl('no-permissions', { state: { previousURL: this.router.url } });
    });
  }

  private makePhoneCall(phoneNumber: string): void {
    logInfo("Making phone call, nexmo session - " + this.nexmoApp?.session?.session_id)
    this.handleMemberCall();
    this.handleCallStatusChange();
    this.nexmoApp.callServer(phoneNumber).catch((error: any) => {
      if (this.callId) {
        this.setCallEvent(this.callId, CallStatusEvent.ERROR);
      }
      logError(`Nexmo could not make a call to phone, message: ${error?.message}`, { nexmoFailure: true, nexmoError: error?.message });
      logError(`Nexmo could not make a call to phone, message's message: ${error?.message?.message}`, { nexmoFailure: true, nexmoError: error?.message });
      logError(`Nexmo could not make a call to phone, error (JSON): ${JSON.stringify(error)}, session_id: ${this.nexmoApp?.session?.session_id}`);
      logError(`Nexmo could not make a call to phone, error (JSON+): ${JSON.stringify(error, ['message', 'name', 'error', 'arguments', 'type'])}, session_id: ${this.nexmoApp?.session?.session_id}`);
      logError(`Nexmo could not make a call to phone, error message (JSON): ${JSON.stringify(error.message)}, session_id: ${this.nexmoApp?.session?.session_id}`);
      console.log(error);
      this.router.navigateByUrl('no-permissions', { state: { previousURL: this.router.url } });
    });
  }

  private handleMemberCall(): void {
    this.nexmoApp.on('member:call', (member: any, call: any) => {
      this.nexmoMember = member;
      this.nexmoCall = call;
      this.setCall();
      this.setNexmoMuteMember();
      if (this.unsubscribe$.isStopped) {
        this.hangUp();
      }
    });
  }

  private handleCallStatusChange(): void {
    this.nexmoApp.on('call:status:changed', (call: any) => {
      this.setCallStatus(call.status);
      if (this.callId) {
        this.setCallEvent(this.callId);
      } else {
        // if we do not have the callId then we did not start a call but we should send event in case it failed
        const nexmoCallStatus = callStatusToEvent[call.status as CallStatus] ?? CallStatusEvent.UNKNOWN;

        const monitoredEvents = [CallStatusEvent.ERROR, CallStatusEvent.FAILED, CallStatusEvent.UNKNOWN];
        if (monitoredEvents.includes(nexmoCallStatus)) {
          const details = {
            callStatus: nexmoCallStatus,
            nexmoFailure: true,
            nexmoStatus: call.status,
            callId: call.id,
            conversationId: call.conversation?.id
          };
          logError(`Could not make a call. CallStatus: ${nexmoCallStatus}`, details);
        }
      }
    });

    this.nexmoApp.on('*', 'NXM-errors', (error: any) => {
      if (error.type) {
        if (this.callId) {
          this.setCallEvent(this.callId, CallStatusEvent.ERROR);
        }
        logError(`NexmoApp error occurred. Error: ${error.type}`, { nexmoFailure: true, nexmoError: error });
      } else {
        const ignoredEvents = [
          'call:status:changed',
          'member:joined',
          'member:call',
          'member:call:status',
          'member:invited',
          'member:left',
          'member:media',
          'audio:mute:off',
          'audio:mute:on',
          'media:stream:on'
        ];
        if (!ignoredEvents.includes(error)) {
          logInfo(`NexmoApp event occurred. Event: ${error}`, { nexmoFailure: true, nexmoEvent: error });
        }
      }
    });
  }

  private setCall(): void {
    this.callService.setCall({
      externalId: this.nexmoCall.conversation.id,
      startTime: new Date().getTime(),
      callMethod: this.resident.contact.type,
      callee: {
        type: CalleeType.RESIDENT,
        id: this.resident.id
      }
    }).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      call => {
        this.callId = call.call.id;
        this.callToken = call.callToken;
      },
      error => {
        if (error.status === 429) {
          const message = `<div class="content-center">
            <i class="fa text-red fa-4x fa-exclamation-circle"></i>
            <br/>
            <h3>Call limit reached</h3>
            You’ve reached the call limit. Please try again in 1 hour.
          </div>`;
          this.showErrorModal(message);
        }
      }
    );
  }

  private setCallEvent(callId: number, callStatus?: CallStatusEvent): void {
    this.callService.setCallEvent(callId, {
      status: callStatus ?? callStatusToEvent[this.callStatus],
      timestamp: new Date().getTime()
    }).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  private setNexmoMuteMember(): void {
    this.nexmoMember.mute(this.muteMember);
  }

  private showErrorModal(message: string): void {
    const dialogConfig: LatchConfirmationDialogConfig = {
      data: {
        messages: [
          message
        ],
        danger: true,
      }
    };
    this.dialog.openConfirmation(dialogConfig);
  }
}
