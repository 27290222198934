import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IntercomUnlock } from '../../models/intercom';
import { HttpService } from '../../providers/http/services/http.service';
import { getHttpHeaders } from '../../utilities/http-headers';
import { GeoLocationService } from '../geo-location/geo-location.service';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  public authToken!: string;

  constructor(
    private httpService: HttpService,
    private geoLocationService: GeoLocationService
  ) { }

  setAuthToken(authToken: string): void {
    this.authToken = authToken;
  }

  checkAuthToken(): boolean {
    return !!this.authToken;
  }

  checkIntercomUnlock(callToken: string): Observable<IntercomUnlock> {
    const httpHeaders = getHttpHeaders(this.authToken, this.geoLocationService.geoLocation);
    return this.httpService.get('/v1/doorCode', { ...httpHeaders, 'call-token': callToken });
  }

}
