import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { logError } from '@latch/latch-web';
import { Issue, IssueType } from 'src/app/models/issue-type';

@Component({
  selector: 'issue-selector',
  templateUrl: './issue-selector.component.html',
  styleUrls: ['./issue-selector.component.scss']
})
export class IssueSelectorComponent implements OnInit {
  @Output()
  closed = new EventEmitter<null>();
  @Output()
  issueSubmitted = new EventEmitter<string>();
  @Input()
  isLocationAllowed = true;

  public IssueType = IssueType;
  public listOfIssues: Issue[] = [];
  public atLeastOneIssueSelected = false;
  public otherIssue = '';
  public selectedIssues: Record<string, boolean> = {};

  ngOnInit(): void {
    if (this.isLocationAllowed) {
      this.listOfIssues = [
        { key: IssueType.POOR_CONNECTION, displayText: 'Issues with sound' },
        { key: IssueType.CANNOT_ESTABLISH_CALL, displayText: 'Cannot establish a call' },
        { key: IssueType.WRONG_UNLOCK_CODE, displayText: 'Wrong Unlock Code' },
        { key: IssueType.CANNOT_GET_UNLOCK_CALL, displayText: 'Cannot get Unlock Code' },
        { key: IssueType.OTHER, displayText: 'Other issue' }
      ];
    } else {
      this.listOfIssues = [
        { key: IssueType.NOT_COMFORTABLE_SHARING_LOCATION, displayText: 'I\'m not comfortable with sharing my location' },
        { key: IssueType.OTHER, displayText: 'Other issue' }
      ];
    }

    this.listOfIssues.forEach(i => {
      this.selectedIssues[i.key] = false;
    });
  }

  selectIssue(issueKey: string) {
    this.selectedIssues[issueKey] = !this.selectedIssues[issueKey];

    this.atLeastOneIssueSelected = false;
    for (const issue in this.selectedIssues) {
      if (this.selectedIssues[issue]) {
        this.atLeastOneIssueSelected = true;
        return;
      }
    }
  }

  submitIssues(): void {
    const reportedIssues: string[] = [];
    for (const issue in this.selectedIssues) {
      if (this.selectedIssues[issue]) {
        reportedIssues.push(issue);
      }
    }
    const details = {
      issues: reportedIssues,
      trackingCode: Math.random().toString(15).substring(8, 13)
    };
    const errorMsg = this.selectedIssues[IssueType.OTHER] ?
      `Call - Negative feedback by client. Description: ${this.otherIssue}`: 'Call - Negative feedback by client.';
    logError(errorMsg, details);
    this.issueSubmitted.emit(details.trackingCode);
  }

  close() {
    this.closed.emit();
  }
}
