<div class="call-container"
  [class]="callStatus === 'uninitialized' ? 'white-background' : 'black-background'">
  <div class="call-content">
    <div class="call-details">
      <div class="call-name">{{resident.name}}</div>
      <div [class]="'call-status ' + callStatus">
        {{statusText}}
        <span *ngIf="callDuration > 0">{{callDuration | formatTime}}</span>
      </div>
    </div>
    <div class="unlock-content" *ngIf="unlockCode">
      <div class="unlock-text">
        <div class="unlock-title">Unlock code</div>
        <div id="unlockCode" class="unlock-code">{{unlockCode}}</div>
      </div>
      <img class="unlock-image" src="../../../assets/images/tap-unlock.gif">
      <div class="unlock-text">
        Tap the black lens on the Latch door lock and enter the code above to unlock the door.
      </div>
    </div>
    <div class="call-buttons" *ngIf="buttonsLabel[callStatus] !== 'none'">
      <div class="call-buttons-container">
        <ng-container *ngIf="buttonsLabel[callStatus] === 'call'">
          <div class="call-button-element">
            <button id="hangUp" class="call-button hang-up" (click)="hangUp()">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </button>
          </div>
          <div class="call-button-element"></div>
          <div class="call-button-element">
            <button id="muteCall" class="call-button mute" [ngClass]="{'active': muteMember}" (click)="toggleMuteMember()">
              <i [class]="'fa '+ (muteMember ? 'fa-microphone-slash' : 'fa-microphone')" aria-hidden="true"></i>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="buttonsLabel[callStatus] === 'back'">
          <button id="backToDirectory" class="button white" (click)="backToDirectory()">Back to Directory</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
