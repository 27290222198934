<div class="container">
  <div class="page">
    <div class="page-wrapper">
      <div class="page-header">
        <div>
          <i class="fa fa-angle-left header-icon" (click)="close()"></i>
        </div>
        <div class="page-title">
          Give Feedback
          <br/>
          <small>
            Please select all that applies.
          </small>
        </div>
      </div>
      <div class="page-content">
        <ul>
          <li *ngFor="let issue of listOfIssues">
            <latch-checkbox label="{{issue.displayText}}" labelPosition="after"
              [checked]="selectedIssues[issue.key]" (checkedChange)="selectIssue(issue.key)"></latch-checkbox>
          </li>
          <li *ngIf="selectedIssues[IssueType.OTHER]">
            <div class="form-control-container">
              <textarea
                type="text"
                class="form-control"
                placeholder="Describe the issue..."
                [(ngModel)]="otherIssue"
                maxlength="200"
              ></textarea>
            </div>
          </li>
        </ul>
      </div>
      <div class="bottom-buttons">
        <button id="submitIssues" class="button blue"
          [disabled]="!atLeastOneIssueSelected" (click)="submitIssues()">Submit</button>
      </div>
    </div>
  </div>
</div>
