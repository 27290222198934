import { Component, OnDestroy, OnInit, Inject, Optional } from '@angular/core';
import { LatchDialogRef, LATCH_DIALOG_DATA, logInfo } from '@latch/latch-web';

enum FeedbackStep {
  InitialScreen,
  IssuesScreen,
  IssueSubmitted,
  ThankYouScreen
}

@Component({
  selector: 'app-feedback-selector',
  templateUrl: './feedback-selector.component.html',
  styleUrls: ['./feedback-selector.component.scss']
})
export class FeedbackSelectorComponent implements OnInit, OnDestroy {
  public step: FeedbackStep = FeedbackStep.InitialScreen;
  public FeedbackStep = FeedbackStep;
  public trackingCode = '';
  public isLocationAllowed = false;

  private closeModalDialogTimeout: any;
  constructor(
    @Inject(LATCH_DIALOG_DATA) @Optional() private dialogData: boolean | null,
    private dialogRef: LatchDialogRef<string>,
  ) { }

  ngOnInit(): void {
    if (this.dialogData) {
      this.isLocationAllowed = this.dialogData;
    }
  }

  ngOnDestroy(): void {
    if (this.closeModalDialogTimeout) {
      clearTimeout(this.closeModalDialogTimeout);
    }
  }

  positiveFeedback(): void {
    this.step = FeedbackStep.ThankYouScreen;
    logInfo('Call - Positive feedback by client.');
    this.autoCloseModal();
  }

  negativeFeedback(): void {
    this.step = FeedbackStep.IssuesScreen;
  }

  issueSubmitted(trackingCode: string): void {
    this.trackingCode = trackingCode;
    this.step = FeedbackStep.IssueSubmitted;
    this.autoCloseModal();
  }

  autoCloseModal(): void {
    this.closeModalDialogTimeout = setTimeout(() => {
      this.closeModal();
    }, 5000);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
