export const decodeJWT = (jwt: string): { [key: string]: string } | null => {
  const jwtComponents = jwt.split('.');
  if (jwtComponents[1]) {
    try {
      return JSON.parse(atob(jwtComponents[1]));
    } catch (e) {
      return null;
    }
  }
  return null;
};
