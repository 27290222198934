import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UnitResident } from 'src/app/models/unit';
import { CallEventRequest, CallEventResponse, CallRequest, CallResponse, CallToken } from '../../models/call';
import { HttpService } from '../../providers/http/services/http.service';
import { getHttpHeaders } from '../../utilities/http-headers';
import { GeoLocationService } from '../geo-location/geo-location.service';
import { IntercomService } from '../intercom/intercom.service';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  public resident$ = new BehaviorSubject<UnitResident | undefined>(undefined);
  public callEnded$= new BehaviorSubject<boolean>(false);

  constructor(
    private httpService: HttpService,
    private intercomService: IntercomService,
    private geoLocationService: GeoLocationService
  ) { }

  getToken(): Observable<CallToken> {
    return this.httpService.get('/v1/calls/token', getHttpHeaders(this.intercomService.authToken, this.geoLocationService.geoLocation));
  }

  setCall(call: CallRequest): Observable<CallResponse> {
    return this.httpService.post('/v1/calls', call, getHttpHeaders(this.intercomService.authToken, this.geoLocationService.geoLocation));
  }

  setCallEvent(callId: number, callEvent: CallEventRequest): Observable<CallEventResponse> {
    const headers = getHttpHeaders(this.intercomService.authToken, this.geoLocationService.geoLocation);
    return this.httpService.post( `/v1/calls/${callId}/events`, callEvent, headers);
  }

  callResident(resident: UnitResident): void {
    this.resident$.next(resident);
  }

  endCall(): void {
    this.resident$.next(undefined);
    this.callEnded$.next(true);
  }
}
