import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnitResident } from 'src/app/models/unit';
import { CallService } from 'src/app/services/call/call.service';
import { PageAnimation } from '../../animations/page-animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [PageAnimation]
})
export class HomeComponent implements OnInit, OnDestroy {
  public activePage!: string;
  public callResident?: UnitResident;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private activeRoute: ActivatedRoute,
    private callService: CallService
  ) { }

  ngOnInit(): void {
    this.callService.resident$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(r => this.callResident = r);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  routerChange(): void {
    window.scrollTo(0, 0);
    this.activePage = this.activeRoute.snapshot.firstChild?.data.name || '';
  }

  endCall(): void {
    this.callService.endCall();
  }
}
