import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { Debounce } from '../../models/debounce';

@Directive({
  selector: '[appDebounce]',
})
export class DebounceDirective implements OnInit {
  @Input() appDebounce!: Debounce;
  @Output() debounce = new EventEmitter<null>();
  constructor(private model: NgControl) { }

  ngOnInit(): void {
    if (this.model.valueChanges) {
      this.model.valueChanges
        .pipe(
          debounceTime(this.appDebounce.debounceTime),
          distinctUntilChanged()
        ).subscribe(() => {
          if (this.appDebounce.skipFirstChange) {
            this.appDebounce.skipFirstChange = false;
          } else {
            this.debounce.emit();
          }
        });
    }
  }
}
