import { Environment } from '../app/models/environment';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://virtual-intercom.staging.latchaccess.com',
  name: 'staging',
  enableLogging: true,
  datadogToken: 'pub14a05d477f7a66126a4bf7ce1d2c4169',
  enableAnalytics: true,
  heapApplicationId: '999849600'
};
