<div class="modal-container">
  <ng-container *ngIf="step === FeedbackStep.InitialScreen">
    <span>
      How was your experience with our app?
    </span>
    <div class="center">
      <div class="circle" (click)="positiveFeedback()">
        <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
      </div>
      <div class="circle margin-left-20" (click)="negativeFeedback()">
        <i class="fa fa-thumbs-o-down" aria-hidden="true" ></i>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === FeedbackStep.ThankYouScreen">
    <span>
      Thank you
    </span>
    <div class="center">
      We’re happy to hear that you’re enjoying the app.
    </div>
  </ng-container>
  <issue-selector
    *ngIf="step === FeedbackStep.IssuesScreen"
    [isLocationAllowed]="isLocationAllowed"
    (closed)="closeModal()"
    (issueSubmitted)="issueSubmitted($event)">
  </issue-selector>
  <ng-container *ngIf="step === FeedbackStep.IssueSubmitted">
    <span>
      Thank you
    </span>
    <div class="center">
      <span>
        Your feedback helps us improve our app experience.
        <br/>
        <p class="text-note">{{trackingCode}}</p>
      </span>
    </div>
  </ng-container>
</div>
