import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeoLocation } from '../../models/geo-location';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  public geoLocation: GeoLocation | null = null;

  getUserGeolocation(): Observable<boolean> {
    return new Observable((observer) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          this.geoLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          observer.next(true);
          observer.complete();
        }, () => {
          observer.error(false);
          observer.complete();
        });
      } else {
        observer.error(false);
        observer.complete();
      }
    });
  }
}
