import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LatchAnalyticsService } from '@latch/latch-web';
import { Observable } from 'rxjs';
import { IntercomService } from '../../services/intercom/intercom.service';
import { decodeJWT } from '../../utilities/decode-jwt';

@Injectable({
  providedIn: 'root'
})
export class IntercomGuard implements CanActivate {
  constructor(
    private intercomService: IntercomService,
    private router: Router,
    private analyticsService: LatchAnalyticsService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.params.jwtToken) {
      this.intercomService.setAuthToken(route.params.jwtToken);
      const jwtData = decodeJWT(this.intercomService.authToken);
      if (jwtData && jwtData.sub) {
        this.analyticsService.track('VI Session Data', {
          virtualIntercomId: jwtData.sub
        });
      }
      return true;
    }
    return this.redirect();
  }

  private redirect(): boolean {
    this.router.navigateByUrl('/no-auth');
    return false;
  }

}
