<div class="page full-height">
  <div class="page-wrapper with-logo">
    <div class="page-logo">
      <img src="./../../../assets/images/latch-logo-black.svg">
    </div>
    <div class="page-content">
      <div class="page-title">Welcome</div>
      <div class="page-description">
        Please share your location to use Latch Link.
      </div>
      <div class="buttons-container">
        <button id="continue" class="button blue" [routerLink]="['../home/units']">Continue</button>
      </div>
    </div>
    <div class="page-footer">
      By using Latch, you agree to our
      <a href="https://www.latch.com/terms-of-service">Terms of Service</a> and
      <a href="https://www.latch.com/privacy-policy">Privacy Policy.</a>
      <br/>
      {{versionNumber}}
    </div>
  </div>
</div>
