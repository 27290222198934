import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Unit, UnitResident } from '../../models/unit';
import { HttpService } from '../../providers/http/services/http.service';
import { getHttpHeaders } from '../../utilities/http-headers';
import { GeoLocationService } from '../geo-location/geo-location.service';
import { IntercomService } from '../intercom/intercom.service';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  constructor(
    private httpService: HttpService,
    private intercomService: IntercomService,
    private geoLocationService: GeoLocationService
  ) { }

  getUnits(): Observable<Unit[]> {
    return this.httpService.get('/v1/units', getHttpHeaders(this.intercomService.authToken, this.geoLocationService.geoLocation));
  }

  getUnit(unitId: number): Observable<Unit> {
    return this.getUnits().pipe(
      map(units => {
        const unit = units.find(u => u.id === unitId);
        if (!unit) {
          throw new Error('Unit not found');
        }
        return unit;
      })
    );
  }

  getFeaturedEntries(): Observable<UnitResident[]> {
    return this.httpService.get('/v1/featuredEntries',
      getHttpHeaders(this.intercomService.authToken, this.geoLocationService.geoLocation)
    );
  }

  getUnitResidents(unitId: number): Observable<UnitResident[]> {
    return this.httpService.get(`/v1/units/${unitId}/residents`,
      getHttpHeaders(this.intercomService.authToken, this.geoLocationService.geoLocation)
    );
  }
}
