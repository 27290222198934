export interface Unit {
  id: number;
  name: string;
  contact: string;
}

export enum ResidentContactType {
  APP = 'APP',
  PHONE = 'PHONE',
  UNIT = 'UNIT'
}

export enum ResidentWarning {
  NONE = 'NONE',
  NEVER_LOGGED_INTO_APP = 'NEVER_LOGGED_INTO_APP'
}

export interface ResidentContact {
  type: ResidentContactType;
  details: string;
}

export interface UnitResident {
  id: number;
  name: string;
  contact: ResidentContact;
  number: string;
  contactCardUuid: string;
  residentWarning: ResidentWarning;
}
