import { transition, trigger } from '@angular/animations';
import {
  bottomToTopTransition,
  leftToRightTransition,
  rightToLeftTransition,
  topToBottomTransition
} from './transitions';

export const PageAnimation =
  trigger('pageAnimations', [
    transition('units => residents', rightToLeftTransition),
    transition('residents => units', leftToRightTransition),
  ]);
