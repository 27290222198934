import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LatchAnalyticsService } from '@latch/latch-web';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { GeoLocationService } from '../../services/geo-location/geo-location.service';
import { LoaderService } from '../../services/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationGuard implements CanActivate {
  constructor(
    private geoLocationService: GeoLocationService,
    private router: Router,
    private loaderService: LoaderService,
    private analyticsService: LatchAnalyticsService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.loaderService.openLoader();
    return this.geoLocationService.getUserGeolocation().pipe(
      finalize(() => this.loaderService.closeLoader()),
      map(() => {
        this.analyticsService.track('VI Geo Location Allowed');
        return true;
      }),
      catchError(() => of(this.redirect(state.url)))
    );
  }

  private redirect(url: string): boolean {
    this.router.navigateByUrl('/no-permissions', { state: { previousURL: url } });
    return false;
  }

}
