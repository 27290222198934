<div class="page full-height black-background">
  <div class="page-wrapper with-logo">
    <div class="page-logo">
      <img src="./../../../assets/images/latch-logo-white.svg">
    </div>
    <div class="page-content">
      <div class="page-title">{{title}}</div>
      <div class="page-description">
        To use the Latch Link, you must complete the steps below and try again.
        <br/>
        <br/>
      </div>
      <button id="tryAgain" class="button blue" (click)="reloadSite()">Try again</button>
      <div class="resolution-steps-container">
        <div class="header">
          {{ deviceCaption }}
        </div>
        <div class="content">
          <ol>
            <li *ngFor="let step of permissionSteps">
                {{step}}
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
