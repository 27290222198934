import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CallService } from 'src/app/services/call/call.service';
import { Debounce } from '../../models/debounce';
import { Unit, UnitResident } from '../../models/unit';
import { LoaderService } from '../../services/loader/loader.service';
import { UnitService } from '../../services/unit/unit.service';

const debounceTime = 300;

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent implements OnInit, OnDestroy {
  public dataLoading = false;
  public filteredUnits: Unit[] = [];
  public filteredFeaturedEntries: UnitResident[] = [];
  public search = new FormControl<string>('', { nonNullable: true });
  public debounce: Debounce = { skipFirstChange: false, debounceTime };

  private units: Unit[] = [];
  private featuredEntries: UnitResident[] = [];

  private unsubscribe$ = new Subject<void>();
  private initialIn = true;
  constructor(
    private unitService: UnitService,
    private callService: CallService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.activeRoute.queryParams.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(params => {
      let takeData = false;
      if ((params.search && this.search.value !== params.search)
        || (!params.search && this.search.value)) {
        this.search.setValue(params.search || '');
        takeData = true;
        if (!this.initialIn) {
          this.debounce.skipFirstChange = true;
        }
      }
      if (takeData || this.initialIn) {
        if (this.initialIn) {
          this.initialIn = false;
          this.getEntities();
        } else {
          this.filterEntities();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  searchDebounce(): void {
    this.filterEntities();
  }

  setCallResident(resident: UnitResident): void {
    this.callService.callResident(resident);
  }

  private filterEntities(): void {
    this.filteredUnits = this.units.filter(
      unit => unit.name.toLowerCase().indexOf(this.search.value.toLowerCase()) > -1
    );
    this.filteredFeaturedEntries = this.featuredEntries.filter(
      entity => entity.name.toLowerCase().indexOf(this.search.value.toLowerCase()) > -1
    );
    const queryParams = this.search.value ? { search: this.search.value } : {};
    this.router.navigate([], { queryParams });
  }

  private getEntities(): void {
    this.loaderService.openLoader();
    this.dataLoading = true;
    forkJoin([this.unitService.getUnits(), this.unitService.getFeaturedEntries()]).pipe(
      finalize(() => {
        this.dataLoading = false;
        this.loaderService.closeLoader();
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(([units, featuredEntries]) => {
      this.units = units;
      this.featuredEntries = featuredEntries;
      this.filterEntities();
    });
  }

}
