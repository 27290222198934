import {
  animate,
  animateChild,
  group,
  query,
  style
} from '@angular/animations';

const baseStyle = { position: 'relative' };

const animationStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100vh',
  overflow: 'hidden'
};

const animationTime = '300ms ease-out';

const topEnter = { transform: 'translateY(-100vh)' };
const bottomEnter = { transform: 'translateY(100vh)' };
const leftEnter = { transform: 'translateX(-100vh)' };
const rightEnter = { transform: 'translateX(100vh)' };

const topLeave = { transform: 'translateY(-100vh)' };
const bottomLeave = { transform: 'translateY(0)' };
const leftLeave = { transform: 'translateX(-100vh)' };
const rightLeave = { transform: 'translateX(100vh)' };

const topBottomFinish = { transform: 'translateY(0)' };
const leftRightFinish = { transform: 'translateX(0)' };

export const topToBottomTransition = [
  style(baseStyle),
  query(':enter, :leave', [
    style(animationStyle)
  ]),
  query(':enter', [
    style(topEnter)
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate(animationTime, style(bottomLeave))
    ]),
    query(':enter', [
      animate(animationTime, style(topBottomFinish))
    ])
  ]),
  query(':enter', animateChild()),
];

export const bottomToTopTransition = [
  style(baseStyle),
  query(':enter, :leave', [
    style(animationStyle)
  ]),
  query(':enter', [
    style(bottomEnter)
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate(animationTime, style(topLeave))
    ]),
    query(':enter', [
      animate(animationTime, style(topBottomFinish))
    ])
  ]),
  query(':enter', animateChild()),
];

export const leftToRightTransition = [
  style(baseStyle),
  query(':enter, :leave', [
    style(animationStyle)
  ]),
  query(':enter', [
    style(leftEnter)
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate(animationTime, style(rightLeave))
    ]),
    query(':enter', [
      animate(animationTime, style(leftRightFinish))
    ])
  ]),
  query(':enter', animateChild()),
];

export const rightToLeftTransition = [
  style(baseStyle),
  query(':enter, :leave', [
    style(animationStyle)
  ]),
  query(':enter', [
    style(rightEnter)
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate(animationTime, style(leftLeave))
    ]),
    query(':enter', [
      animate(animationTime, style(leftRightFinish))
    ])
  ]),
  query(':enter', animateChild()),
];
