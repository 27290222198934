import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeoLocationGuard } from './guards/geo-location/geo-location.guard';
import { IntercomGuard } from './guards/intercom/intercom.guard';
import { IntercomGuardV2 } from './guards/intercom/intercom.v2.guard';
import { HomeComponent } from './pages/home/home.component';
import { IntercomComponent } from './pages/intercom/intercom.component';
import { NoAuthComponent } from './pages/no-auth/no-auth.component';
import { NoPermissionsComponent } from './pages/no-permissions/no-permissions.component';
import { UnitResidentsComponent } from './pages/unit-residents/unit-residents.component';
import { UnitsComponent } from './pages/units/units.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';

const routes: Routes = [
  { path: '', redirectTo: '/no-auth', pathMatch: 'full' },
  {
    path: 'intercoms/:jwtToken', canActivate: [IntercomGuard], component: IntercomComponent, children: [
      { path: '', redirectTo: 'welcome', pathMatch: 'full' },
      { path: 'welcome', component: WelcomeComponent, data: { name: 'welcome' } },
      {
        path: 'home', component: HomeComponent, canActivate: [GeoLocationGuard], data: { name: 'home' }, children: [
          { path: '', redirectTo: 'units', pathMatch: 'full' },
          { path: 'units', component: UnitsComponent, data: { name: 'units' } },
          { path: 'units/:unitId/residents', component: UnitResidentsComponent, data: { name: 'residents' } },
          { path: '**', redirectTo: 'units' },
        ]
      },
      { path: '**', redirectTo: 'welcome' },
    ]
  },
  {
    path: 'v2/intercoms/:tokenV2', canActivate: [IntercomGuardV2], component: IntercomComponent, children: [
      { path: '', redirectTo: 'welcome', pathMatch: 'full' },
      { path: 'welcome', component: WelcomeComponent, data: { name: 'welcome' } },
      {
        path: 'home', component: HomeComponent, canActivate: [GeoLocationGuard], data: { name: 'home' }, children: [
          { path: '', redirectTo: 'units', pathMatch: 'full' },
          { path: 'units', component: UnitsComponent, data: { name: 'units' } },
          { path: 'units/:unitId/residents', component: UnitResidentsComponent, data: { name: 'residents' } },
          { path: '**', redirectTo: 'units' },
        ]
      },
      { path: '**', redirectTo: 'welcome' },
    ]
  },
  { path: 'no-auth', component: NoAuthComponent },
  { path: 'no-permissions', component: NoPermissionsComponent },
  { path: '**', redirectTo: '/no-auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
