export enum MobileDevice {
  UNKNOWN,
  ANDROID,
  APPLE
}

export enum Browser {
  UNKNOWN,
  CHROME,
  SAFARI,
  OTHER
}
